.tableContainerOrgaos {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select {
  width: 97%;
}
.MuiInputLabel-root {
  margin-left: 1.1rem;
}
.divEmpresas {
  display: flex;
  justify-content: right;
}
.stackContainer {
  background-color: #fff;
  width: 1200px;
  min-height: 175px;
  max-height: 750px;
  border-radius: 0.75rem;
  margin-top: 2rem;
}
.stackContainerGrid {
  background-color: #fff;
  min-width: 500px;
  width: 100%;
  max-width: 1200px;
  min-height: 175px;
  max-height: 750px;
  border-radius: 0.75rem;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .stackContainer {
    width: 340px;
    position: relative;
    left: 30px;
    margin-top: 10px;
  }
  .stackContainerGrid {
    width: 380px;
    position: relative;
    left: 10px;
    margin-top: 12px;
  }
  .divEmpresas {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
