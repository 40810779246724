.containerButtonNovaNotif {
  display: flex;
  justify-content: right;
}
.ProgressBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 125px;
  left: 300px;
  z-index: 10000;
}
/* @media (max-width: 390px) {
  .containerButtonNovaNotif {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    position: relative;
    left: 35px;
  }
  .ProgressBar {
    position: relative;
    top: 125px;
    left: 135px;
  }
} */

@media (max-width: 768px) {
  .containerButtonNovaNotif {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .ProgressBar {
    position: relative;
    top: 125px;
    left: 135px;
  }
}
