#formEmpresas {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .formEmpresas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */
.FormCNPJ {
  display: flex;
  justify-content: center;
  align-items: center;
}
#padraoInput {
  border-radius: 0.75rem;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
/* #padraoInput:first-child {
  margin-top: 15px;
} */

#padraoInput:hover {
  background-color: #ccc;
}
#padraoInput:focus {
  outline: 2px solid #270082;
}
#inputIE {
  border-radius: 0.75rem;
  width: 410px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
#inputIE:first-child {
  margin-right: 15px;
}
#inputIE:hover {
  background-color: #ccc;
}
#inputIE:focus {
  outline: 2px solid #270082;
}
/* .formEndereco {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
} */

#inputEndereco {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}

#inputEndereco:hover {
  background-color: #ccc;
}
#inputEndereco:focus {
  outline: 2px solid #270082;
}
#Button {
  background-color: #262755;
}
#divSalvarDados {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 30px;
}
.enderecoComplementos {
  display: flex;
}
.CNPJForm {
  margin-bottom: 1rem;
  width: 300px;
}
.RazaoSocialForm {
  width: 520px;
  margin-left: 20px;
}
.InscEstForm {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-right: 1rem;
}
.InscMunForm {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-right: 1rem;
}
.simplesNacional {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.CEPForm {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 300px;
}
.EnderecoForm {
  width: 500px;
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.CompleForm {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.BairroForm {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.UFForm {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.CidadeForm {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.SwitchEmpresa {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.divCep {
  display: flex;
  margin-top: 20px;
}
.divEndereco {
  display: flex;
  justify-content: left;
  align-items: center;
}
.buttonSalvar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.NumForm {
  width: 100px;
}
.divEnderecoCompleto {
  position: relative;
  left: 30px;
}
.divCNPJ {
  margin-top: 1rem;
}
.divInscricoes {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* @media (max-width: 390px) {
  .CNPJForm {
    width: 110px;
    margin-left: 5px;
    position: relative;
    right: 5px;
  }
  .CEPForm {
    width: 230px;
  }
  #padraoInput:nth-child(2) {
    margin-bottom: 0;
  }
  .RazaoSocialForm {
    width: 120px;
    position: relative;
  }
  .InscEstForm {
    width: 110px;
  }
  .InscMunForm {
    width: 110px;
  }
  .simplesNacional {
    width: 120px;
  }
  .divInscricoes {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .NumForm {
    width: 50px;
  }
  .EnderecoForm {
    width: 150px;
  }
  .CompleForm {
    width: 150px;
  }
  .BairroForm {
    width: 50px;
  }
  .UFForm {
    width: 50px;
  }
  .CidadeForm {
    width: 150px;
  }
} */
.textTooltip {
  position: relative;
  right: 20px;
}
.tooltipMensagem {
  word-break: break-all;
  line-break: auto;
}
.iconTooltip {
  position: relative;
  top: 7px;
  right: 15px;
}
.tooltipMensagem {
  line-break: auto;
}
@media (max-width: 428px) {
  .CNPJForm {
    width: 120px;
    position: relative;
    right: 10px;
  }
  .buttonSalvar {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .divEnderecoCompleto {
    position: relative;
    left: 0;
  }
  .divEnderecoCompleto {
    position: relative;
    left: -10px;
  }
  .enderecoComplementos {
    display: inline;
  }
  .divInscricoesFormEdit {
    display: block;
    flex-flow: row wrap;
  }
  .divCep {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .CEPForm {
    width: 235px;
  }
  .divInscricoes {
    display: block;
    flex-flow: row wrap;
  }
  #padraoInput:nth-child(2) {
    margin-bottom: 0;
  }
  .RazaoSocialForm {
    width: 120px;
    position: relative;
    right: 15px;
  }
  .InscEstForm {
    width: 120px;
    position: relative;
  }
  .InscMunForm {
    width: 120px;
    position: relative;
  }
  .simplesNacional {
    margin-top: 1rem;
    position: relative;
    left: 5px;
  }
  .divEnderecoCompleto {
    position: relative;
    right: 20px;
  }
  .divCep {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .NumForm {
    width: 50px;
  }
  .divEndereco {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divComplemento {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divCidade {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .EnderecoForm {
    width: 160px;
  }
  .CompleForm {
    width: 160px;
  }
  .BairroForm {
    width: 50px;
  }
  .UFForm {
    width: 50px;
  }
  .CidadeForm {
    width: 160px;
  }
}
