#editarEmpresa {
  border-radius: 0.75rem;
  margin: 5px;
  padding: 8px 16px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.novaEmpresa {
  position: relative;
  left: 97%;
  bottom: 25px;
}
