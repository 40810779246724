.linha1 {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#caixaLinha2 {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.MuiCard-root {
  width: 235px;
}
.caixasTitle {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 1rem;
}
/* @media (max-width: 834px) {
  .MuiCard-root {
    width: 200px;
    height: 200px;
  }
} */
/* @media (min-width: 375px) {
  .conteudoCaixas2 {
    position: relative;
    left: 75px;
    margin-left: 6px;
  }
}
@media (min-width: 414px) {
  .conteudoCaixas2 {
    position: relative;
    left: 75px;
    margin-left: 12px;
  }
} */
/* @media (min-width: 390px) {
  .conteudoCaixas2 {
    position: relative;
    left: 75px;
    margin-left: 8px;
  }
} */
/* @media (min-width: 428px) {

  .conteudoCaixas2 {
    position: relative;
    left: 75px;
    margin-left: 18px;
  }
} */
@media (max-width: 768px) {
  .conteudoCaixas {
    position: relative;
    left: 10px;
  }
  .conteudoCaixas2 {
    position: relative;
    left: 88px;
  }
  #caixaLinha2 {
    margin-top: 15px;
  }
  .caixasTitle {
    font-size: 1rem;
    margin-top: 5px;
  }
  .spanCaixas {
    font-size: 0.9rem;
  }
  .iconsCaixas {
    margin-top: -20px;
  }
  ::-webkit-scrollbar {
    width: 9px;
  }
  .MuiCard-root {
    width: 190px;
    height: 150px;
  }
  .MuiTypography-body6 {
    font-size: 1rem;
  }
  .caixas {
    margin-bottom: 15px;
  }
  .caixas:last-child {
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  }
}

/* 
@media (max-width: 730px) {
  .conteudoCaixas {
    margin-left: 70px;
  }
  .conteudoCaixas2 {
    margin-left: 59px;
  }
  #caixaLinha2 {
    margin-top: 15px;
  }
  .caixasTitle {
    font-size: 1rem;
    margin-top: 5px;
  }
  .spanCaixas {
    font-size: 0.9rem;
  }
  .iconsCaixas {
    margin-top: -20px;
  }
  ::-webkit-scrollbar {
    width: 9px;
  }
  .MuiCard-root {
    width: 190px;
    height: 150px;
  }
  .MuiTypography-body6 {
    font-size: 1rem;
  }
  .caixas {
    margin-bottom: 15px;
  }
  .caixas:last-child {
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
    border-radius: 9px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 9px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
  }
} */
