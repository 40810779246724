#formEmpresas {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .formEmpresas {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */
#padraoInput {
  border-radius: 0.75rem;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
#padraoInput:first-child {
  margin-top: 15px;
}

#padraoInput:hover {
  background-color: #ccc;
}
#padraoInput:focus {
  outline: 2px solid #270082;
}
#inputIE {
  border-radius: 0.75rem;
  width: 410px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
#inputIE:first-child {
  margin-right: 15px;
}
#inputIE:hover {
  background-color: #ccc;
}
#inputIE:focus {
  outline: 2px solid #270082;
}

#inputEndereco {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
#inputEndereco:hover {
  background-color: #ccc;
}
#inputEndereco:focus {
  outline: 2px solid #270082;
}
#Button {
  background-color: #262755;
}
.CNPJFormEdit {
  margin-bottom: 1rem;
  width: 300px;
  border-radius: 0.75rem;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-right: 10px;
}
.RazaoSocialFormEdit {
  width: 520px;
  border-radius: 0.75rem;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.InscEstFormEdit {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-right: 1rem;
}
.InscMunFormEdit {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-right: 1rem;
}
.SimplesNacionaFomEdit {
  border-radius: 0.75rem;
  width: 265px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.CEPFormEdit {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 300px;
}
.EnderecoFormEdit {
  width: 500px;
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.CompleFormEdit {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.BairroFormEdit {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.UFFormEdit {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.CidadeFormEdit {
  border-radius: 0.75rem;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 8px 8px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  width: 150px;
}
.SwitchEmpresaEdit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.divCepEdit {
  display: flex;
  margin-top: 20px;
}
.divEnderecoEdit {
  display: flex;
  justify-content: left;
  align-items: center;
}
.buttonSalvarEdit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.NumFormEdit {
  width: 100px;
}
.enderecoCompletoFormEdit {
  display: flex;
}
.divEnderecoCompletoEdit {
  position: relative;
  left: 30px;
}
/* @media (max-width: 390px) {
  .divCNPJ {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divEnderecoCompletoEdit {
    position: relative;
    left: 0;
  }
  .CNPJFormEdit {
    width: 110px;
    margin-bottom: 0px;
  }
  .RazaoSocialFormEdit {
    width: 110px;
  }
  .InscEstFormEdit {
    width: 110px;
  }
  .InscMunFormEdit {
    width: 110px;
  }
  .CEPFormEdit {
    width: 230px;
  }
  .EnderecoFormEdit {
    width: 150px;
  }
  .NumFormEdit {
    width: 50px;
  }
  .CompleFormEdit {
    width: 150px;
  }
  .BairroFormEdit {
    width: 50px;
  }
  .UFFormEdit {
    width: 50px;
  }
  .CidadeFormEdit {
    width: 150px;
  }
} */
@media (max-width: 428px) {
  .divCNPJ {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divEnderecoCompletoEdit {
    position: relative;
    left: 10px;
  }
  .enderecoCompletoFormEdit {
    display: inline;
    margin-bottom: 0;
  }
  .SimplesNacionaFomEdit {
    margin-top: 1rem;
    position: relative;
    left: -5px;
  }
  .CNPJFormEdit {
    width: 110px;
    position: relative;
    right: 10px;
  }
  .RazaoSocialFormEdit {
    width: 110px;
    position: relative;
    bottom: 7px;
    right: 8px;
  }
  .InscEstFormEdit {
    width: 110px;
  }
  .InscMunFormEdit {
    width: 110px;
  }
  .CEPFormEdit {
    width: 230px;
  }
  .EnderecoFormEdit {
    width: 150px;
  }
  .NumFormEdit {
    width: 50px;
  }
  .CompleFormEdit {
    width: 150px;
  }
  .BairroFormEdit {
    width: 50px;
  }
  .UFFormEdit {
    width: 50px;
  }
  .CidadeFormEdit {
    width: 150px;
  }
  .buttonSalvarEdit {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
