.containerPhoneInput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.containerSwitchNot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pNotif {
  text-align: center;
  margin-top: 1rem;
}
.PhoneInputInput {
  border-radius: 0.75rem;
  margin: 5px;
  padding: 8px 16px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
.PhoneInputInput:hover {
  background-color: #ccc;
}
.PhoneInputInput:focus {
  outline: 2px solid #270082;
}
#inputEmailNotificacao {
  border-radius: 0.75rem;
  width: 300px;
  margin-left: 5px;
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #eee;
  color: #252525;
  border: transparent;
  font-size: 1rem;
}
#inputEmailNotificacao:hover {
  background-color: #ccc;
}
#inputEmailNotificacao:focus {
  outline: 2px solid #270082;
}
.inputNotificao {
  width: 300px;
  margin-top: 1rem;
}

@media (max-width: 390px) {
  .containerPhoneInput {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .PhoneInputInput {
    width: 100px;
    margin-top: 1rem;
    font-size: 1rem;
  }
  #inputEmailNotificacao {
    width: 200px;
    margin-top: 1rem;
    position: relative;
    right: 10px;
    font-size: 1rem;
  }
  .MuiFormControl-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiTypography-root
    .MuiTypography-body1
    .MuiFormControlLabel-label
    .css-16brm1x-MuiTypography-root {
    font-size: 0.75rem;
  }
  .css-lwkh1d-MuiFormLabel-root-root {
    font-size: 1rem;
  }
}
@media (max-width: 428px) {
  .containerPhoneInput {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .PhoneInputInput {
    width: 100px;
    margin-top: 1rem;
    font-size: 1rem;
  }
  #inputEmailNotificacao {
    width: 200px;
    margin-top: 1rem;
    position: relative;
    right: 10px;
    font-size: 1rem;
  }
  .MuiFormControl-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiTypography-root
    .MuiTypography-body1
    .MuiFormControlLabel-label
    .css-16brm1x-MuiTypography-root {
    font-size: 0.75rem;
  }
  .css-lwkh1d-MuiFormLabel-root-root {
    font-size: 1rem;
  }
}
