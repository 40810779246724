.containerTotal {
  padding: 16px;
  color: #252525;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.75rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.item-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h3 {
  font-family: 'Nova Mono';
  text-align: left;
  margin: 10px;
}

.pEmpresa {
  font-family: 'Nova Mono';
  font-weight: bold;
  color: #2b2c5f;
  text-align: center;
  margin-bottom: 10px;
}
.item-2,
.item-3,
.item-4,
.item-5,
.item-6 {
  margin-bottom: 4px;
  background-color: #fff;
  padding: 16px;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid lightgray;
  margin-top: 10px;
}
.table,
.th,
.td {
  padding: 8px;
  color: #252525;
  text-align: center;
  border: 1px solid lightgray;
}
.chart-container {
  width: 500px;
  height: 300px;
}
@media (max-width: 768px) {
  .divDadosEmpresa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .h3Empresa {
    font-size: 1rem;
    word-break: break-all;
  }
  .pEmpresa {
    font-size: 1rem;
    word-break: break-all;
  }
  h3 {
    margin: 0px;
  }
  .containerTotal {
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    max-width: 350px;
  }
}
