.selectEmpresa {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px;
}
.stackBtn {
  margin-left: 8px;
  margin-top: 10px;
}
#senhaCertificado {
  border-radius: 0.4rem;
  width: 250px;
  margin: 5px;
  padding: 8px 16px;
  background-color: #e5e2e2;
  color: #252525;
  border: transparent;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
#senhaCertificado:hover {
  background-color: #ccc;
}
#senhaCertificado:focus {
  outline: 1px solid #270082;
}
#dividerCertificado {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#buscaFiliais {
  border-radius: 0.75rem;
  width: 500px;
  margin: 5px;
  background-color: #e5e2e2;
  color: #252525;
  border: transparent;
  margin-top: 20px;
  margin-bottom: 20px;
}
#buscaFiliais:hover {
  background-color: #ccc;
}
#buscaFiliais:focus {
  outline: 2px solid #270082;
}
#inputBuscaFilial {
  display: flex;
  justify-content: center;
}
#labelTableFiliais {
  text-align: center;
  background-color: #2b2c5f;
}
#infosFiliais {
  background-color: #eee;
}
#btnSalvarFiliais {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
