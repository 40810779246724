span,
p,
table {
  padding: 0;
  margin: 0;
}
#labelTable {
  text-align: center;
  background-color: #2b2c5f;
  color: #fff;
}
#inputFiliais {
  margin-left: 3px;
  margin-right: 3px;
  background-color: #eee;
  width: 120px;
}
.tableFilials {
  position: relative;
  right: 10px;
}
#inputFiliais:focus {
  outline: 1px solid #2b2c5f;
  background-color: #ccc;
}
#addInput {
  position: relative;
  left: 96%;
  bottom: 5px;
}
.stackFiliais {
  background-color: #fff;
  width: auto;
  min-height: auto;
  max-height: 650px;
  overflow-y: scroll;
  padding: 3px;
  border-radius: 0.75rem;
}
.FilialTable {
  max-height: 500px;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 0.75rem;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 0.75rem;
}
::-webkit-scrollbar-thumb {
  background-color: #2b2c5f;
  border-radius: 0.75rem;
}
@media (max-width: 390px) {
  #addInput {
    position: relative;
    left: 86%;
  }
  .stackFiliais {
    background-color: #fff;
    max-height: 300px;
    width: 290px;
    overflow-y: scroll;
    padding: 3px;
    border-radius: 0.75rem;
  }
}
@media (max-width: 428px) {
  #addInput {
    position: relative;
    left: 86%;
  }
  .stackFiliais {
    background-color: #fff;
    max-height: 300px;
    width: 290px;
    overflow-y: scroll;
    padding: 3px;
    border-radius: 0.75rem;
  }
}
