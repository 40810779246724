ul,
li,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}
.containerMensagens {
  display: grid;
  grid-template-columns: 400px 781px;
  background-color: #fff;
  justify-content: center;
  color: #252525;
  width: 1190px;
  height: 670px;
  padding: 5px;
  margin-top: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 0.75rem;
}
.contentMensagens {
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 0.75rem;
}

.statusMensagemProcessamento {
  width: 120px;
  height: 35px;
  background-color: #fff;
  align-items: center;
  justify-content: right;
  border-radius: 0.55rem;
  position: relative;
  left: 1050px;
}
.pStatus {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
  top: 5px;
}
@media (max-width: 768px) {
  .statusMensagemProcessamento {
    position: relative;
    left: 200px;
    margin-top: 10px;
    width: 110px;
    height: 30px;
  }
  .pStatus {
    position: relative;
    font-size: 1rem;
  }
  .containerMensagens {
    display: grid;
    grid-template-columns: 340px;
    width: 350px;
    height: 650px;
    overflow-x: hidden;
    position: relative;
    left: 5px;
  }
  .federal {
    position: relative;
    left: 20px;
  }
  body {
    overflow-x: hidden;
  }
  .contentMensagens {
    display: none;
  }
  .divScroll {
    overflow-y: auto;
    display: grid;
    grid-area: -1;
    grid-template-rows: 100px;
    border: 1px solid rgba(43, 44, 95, 0.3);
    border-radius: 0.75rem;
  }
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2b2c5f;
    display: none;
  }
  .containerMensagens {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.divScroll {
  overflow-y: auto;
  display: grid;
  grid-area: -1;
  grid-template-rows: 300px;
  /* border: 1px solid rgba(43, 44, 95, 0.3); */
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
::-webkit-scrollbar-thumb {
  background-color: #2b2c5f;
}
.TabNav li {
  /* padding-left: 10px; */
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  cursor: pointer;
}
.remetente {
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.bgMensagens {
  padding: 10px 5px 5px 5px;
  background-color: rgba(43, 44, 95, 0.1);
}
.assunto {
  font-size: small;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}
.statusMensagem {
  margin-top: 5px;
  margin-bottom: 0;
  display: flex;
  justify-content: right;
}
.razaoSocialMensagens {
  font-size: small;
  margin-bottom: 5px;
  margin-top: 5px;
}
.dataRecebimento {
  text-align: right;
  margin-right: 10px;
  margin-top: 1rem;
  font-size: small;
  color: #8c8c8c;
}
.divSelections {
  margin: 0 auto;
}
/* .containerLidas {
  max-width: 300px;
  margin-top: 5px;
  margin-bottom: 10px;
} */
.contentMensagens {
  padding: 1rem;
}
.conteudoAssunto {
  text-align: center;
  font-size: 1.2rem;
}
.conteudoData {
  color: #8c8c8c;
  margin-bottom: 1rem;
  text-align: left;
}
.conteudoMensagem {
  margin: 1rem;
}
.containerAcoes {
  margin-top: 1rem;
}
.divSelections {
  margin-top: 1.3rem;
}
.conteudoRazaoSocial {
  color: #8c8c8c;
  font-size: medium;
  margin-top: 1rem;
  text-align: left;
}
.userDataMensagem {
  margin-right: 5px;
}
