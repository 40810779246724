body {
  font-family: Calibri, 'Trebuchet MS', sans-serif !important;
  background: linear-gradient(90deg, #2b2c5f 0%, #9b2c5e 100%);
  color: #fff;
  overflow-x: hidden;
  -webkit-font--smoothing: antialiased;
  display: flex;
  justify-content: center;
}

h1 {
  font-family: 'Nova Mono', monospace;
}

.containerBtnLogout {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 45px;
  top: 0px;
  left: 20px;
  z-index: 10000;
}
.logout {
  background: none;
  border: none;
  color: #2b2c5f;
  /* color: #fff; */
  cursor: pointer;
}

.tableContainer {
  background-color: #fff;
  height: 500px;
  width: auto;
}

/* .animeLeft {
  transform: translateX(-20px);
  opacity: 0;
  animation: animeLeft 0.3s forwards;
}

@keyframes animeLeft {
  to {
    transform: initial;
    opacity: initial;
  }
} */
