.headerSite {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  max-height: 100px;
}
.LogoPollvo {
  cursor: pointer;
  margin-top: 20px;
}
.lembrarSenha {
  display: flex;
}
.Radar {
  margin-top: 20px;
  font-family: 'Nova Mono', monospace;
  font-size: 2rem;
}
.formulario {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
input {
  text-align: center;
  border: none;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1rem;
  font-weight: lighter;
}
input[type='email'].emailLogin,
input[type='password'].senhaLogin {
  background-color: #e5e2e2;
  color: #252525;
  box-sizing: border-box;
  border: none;
  width: 300px;
}
.emailLogin,
.senhaLogin {
  margin-bottom: 25px;
  padding: 10px 8px;
  border-radius: 0.35rem;
}
.loginBTN {
  text-align: center;
}
button {
  border: none;
  background: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  font-weight: lighter;
  font-family: Calibri, 'Trebuchet MS', sans-serif !important;
}
.botaoLogar {
  padding: 8px 96px 8px 90px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  border-radius: 9px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.botaoLogar:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: white;
  opacity: 1;
}
#botao {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px 0px 8px 0px;
  width: 217px;
  border-radius: 9px;
  margin-top: 10px;
}
button {
  transition: all 0.3s ease;
}
#botao:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: white;
  opacity: 1;
}
.LinkEsqueciSenha {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: #fff;
}
.message {
  text-align: center;
  color: red;
}
.bemVindo {
  text-align: center;
  color: #fff;
  font-size: 2.3rem;
  font-family: 'Nova Mono', monospace !important;
  font-weight: lighter !important;
  margin-top: 10px;
  margin-bottom: 15px;
}
.section {
  text-align: center;
  margin-top: 10px;
  font-family: Calibri, 'Trebuchet MS', sans-serif;
  font-weight: lighter;
  font-size: 1.1rem;
  margin-top: 18rem;
  z-index: 1;
}
.RPA {
  cursor: pointer;
}
.background {
  position: fixed;
  top: 310px;
  left: 50.3%;
  opacity: 0.4;
  vertical-align: middle;
  z-index: -1;
}
.sonarLogo {
  width: 100px;
}
.LogoPollvo {
  width: 200px;
}
@media (max-width: 700px) {
  .lembrarSenha {
    font-size: 0.85rem;
  }
  .bemVindo {
    font-size: 1rem;
  }
  .Radar {
    font-size: 1.5rem;
  }
  .sonarLogo {
    width: 70px;
    height: 70px;
    position: relative;
    top: 11px;
    left: 3px;
  }
  .LogoPollvo {
    width: 150px;
  }
  input[type='email'],
  input[type='password'] {
    width: 250px;
  }
  .section {
    margin-top: 10rem;
  }
  .contato {
    font-size: 1rem;
  }
  .background {
    display: none;
  }
}
