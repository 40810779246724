.sonarLogoBranco {
  margin-top: -15px;
  text-align: center;
  margin-bottom: -25px;
  width: 100px;
}
.headerInicio {
  display: flex;
}

.paragrafoSonarHeader {
  font-size: 2.1rem;
  font-family: 'Nova Mono', monospace;
  margin-right: -15px;
  margin-top: 5px;
}
@media (max-width: 900px) {
  .headerSite {
    margin-left: 30px;
  }
  .sonarLogoBranco {
    width: 80px;
    height: 80px;
  }
  .paragrafoSonarHeader {
    font-size: 1.7rem;
  }
}
