.dialogContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.divCND {
  text-align: center;
  gap: 5px;
}
.pDemaisEnd {
  color: #252525;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0px;
}
a.aLink {
  text-decoration: none;
  color: #ffff;
}
.imprimir {
  display: flex;
  justify-content: flex-end;
}

#showMe {
  animation: cssAnimation 0s 7s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}
