.containerInputs {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-rows: 100px;
  /* margin-top: 4rem; */
}
.formulario {
  display: flex;
  flex-direction: column;
}
a.linkVoltarLogin {
  text-decoration: none;
  color: #fff;
  margin-top: 2rem;
}
#emailReset {
  margin-top: 2rem;
  background-color: rgb(229, 226, 226);
  color: #252525;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px 45px;
  border-radius: 0.35rem;
  width: 300px;
}
.codigoReset {
  background-color: rgb(229, 226, 226);
  color: #252525;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 8px 45px;
  border-radius: 0.35rem;
  width: 300px;
}
#botaoReset {
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: 5px;
  padding: 8px 45px 8px 45px;
  text-align: center;
  border-radius: 0.35rem;
  color: #ccc;
  cursor: pointer;
  font-family: 'Calibri';
  font-weight: lighter;
  font-size: 1rem;
}
#botaoReset:hover {
  background-color: rgba(0, 0, 0, 0.16);
  color: white;
  opacity: 1;
}
.textoResetar {
  font-size: 2rem;
  font-family: 'Nova Mono';
}
.codigoReset {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
