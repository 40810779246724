.datePickers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: row;
}
.antdPicker {
  border-color: #2b2c5f;
}
.ant-picker-cell,
.ant-picker-cell-in-view,
.ant-picker-cell-range-hover {
  outline-color: #2b2c5f;
}
.antdPicker:hover {
  border-color: #9b2c5e;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #9b2c5e;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-range
  .ant-picker-active-bar {
  background-color: #2b2c5f;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-today-btn {
  color: #9b2c5e;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell:hover {
  color: #9b2c5e;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-header-view
  button:hover {
  color: #9b2c5e;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: #9b2c5e;
  color: white;
}
:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #eee;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #eee;
}
.divTypo {
  padding: 10px;
  text-align: start;
  margin-left: 5px;
}
.cardsConsultas {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.chartPizza {
  max-width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 0.75rem;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.tableConsultas {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 0.75rem;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.css-vtbyr3-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
}

@media (max-width: 768px) {
  .datePickers,
  .cardsConsultas {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .chartPizza {
    width: 100%;
  }
}
